import React, { useEffect, useContext } from "react"
import { navigate } from "gatsby"
import { summary, summary__link } from "./styles/summary.module.scss"
import { AnimatedButton } from "@components/shared"
import { WPApi } from "@api"


const PurchaseFailed = ({ orderId, msg, phase }) => {
  // useEffect(() => {
  //   if (phase !== "idle") {
  //     WPApi.deleteToken()
  //       .then((data) => {
  //         console.log(data)
  //         localStorage.removeItem("token")
  //       })
  //       .catch((err) => {
  //         console.log(err)
  //       })
  //   }
  // }, [phase])
  return (
    <div className={summary}>
      {phase === "idle" ? (
        <div>
          <h2>Twoja płatność jest przetwarzana. Prosimy o cierpliwość.</h2>
          <p>W przypadku problemów prosimy o kontakt</p>
        </div>
      ) : (
        <div>
          <h2>Oops, wystąpił błąd 🙁</h2>
          <p>Stwórz zamówienie ponownie, lub skontaktuj się z nami.</p>
          <AnimatedButton
            className={summary__link}
            onClick={() => navigate("/")}
          >
            Strona główna
          </AnimatedButton>
        </div>
      )}
    </div>
  )
}
export default PurchaseFailed
