import axios from 'axios';

const localhostGroupId = 107056684;

const instance = axios.create({
    baseURL: 'https://api.mailerlite.com/api/v2/',
    timeout: 1000 * 60,
    headers: {
        'Content-Type': 'application/json',
        'X-MailerLite-ApiKey': 'ae2839d117f0772fb10feb694252907a',
        'Access-Control-Allow-Headers':
            'X-MailerLite-ApiKey,Origin, Content-Type, X-Auth-Token',
    },
});

class MAILERLITE {
    constructor() {}

    subscribeNewPerson() {
        instance
            .post(`groups/${localhostGroupId}/subscribers`, {
                email: 'test@test.pl',
                name: 'Kacper',
            })
            .then((response) => console.log(response))
            .catch((error) => console.log(error));
    }
}

const MailerLiteApi = new MAILERLITE();

export default MailerLiteApi;
