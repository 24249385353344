import React, { useEffect, useContext } from "react"
import { summary, summary__link } from "./styles/summary.module.scss"
import { AnimatedButton } from "@components/shared"
import { navigate } from "gatsby"
import { WPApi } from "@api"
const PurchaseCompleted = ({ orderId }) => {
  useEffect(() => {
    WPApi.deleteToken()
      .then((data) => {
        console.log(data)
        localStorage.removeItem("token")
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])
  return (
    <div className={summary}>
      <h2>Dziękujemy za udany zakup! 🙂</h2>
      <p>
        Teraz już możesz wrócić do czytania wartościowych treści na moim blogu
      </p>
      <AnimatedButton className={summary__link} onClick={() => navigate("/")}>
        Strona główna
      </AnimatedButton>
    </div>
  )
}
export default PurchaseCompleted
