import { request } from "./request"
import WOOCOMMERCE from "../env/WOOCOMMERCE"
const { KEY, SECRET } = WOOCOMMERCE.APIAUTH

class WPAPI {
  constructor() {
    this.route = "/wp-json/wc/v3"
  }
  getDiscount(items, coupon) {
    const url = `wp-json/v1/coupons/check-basket-discount`
    const config = {
      method: "POST",
      data: {
        items: items,
        coupon: coupon,
      },
    }
    return request(url, config)
  }

  postCreateOrder(customer, cart, shipping, coupon = {}, token) {
    const url = this.route + `/orders`
    const config = {
      method: "POST",
      withCredentials: true,
      headers: { Authorization: `Bearer ${token}` },
      data: {
        billing: customer,
        shipping: customer,
        customer_note: customer.note,
        line_items: cart,
        shipping_lines: [shipping],
        ...coupon,
      },
    }
    return request(url, config)
  }

  changeOrder(id, data = { status_order: "completed" }) {
    const token = localStorage.getItem("token")
    const url = `wp-json/wp/v2/orders/status/?id=${id}`
    const config = {
      method: "PUT",
      headers: { Authorization: `Bearer ${token}` },
      withCredentials: true,
      data,
    }
    return request(url, config)
  }

  searchByPaymentId(orderId) {
    const token = localStorage.getItem("token")
    const url = `wp-json/wp/v2/orders/searchById/?search=${orderId}`
    const config = {
      method: "GET",
      withCredentials: true,
    
      headers: { Authorization: `Bearer ${token}` },
    }
    return request(url, config)
  }

  search(phrase = "") {
    const urlProducts = this.route + `/products?search=${phrase}`
    const configProducts = {
      method: "GET",
      withCredentials: true,
      auth: {
        username: KEY,
        password: SECRET,
      },
    }
    const orders = () => request(urlProducts, configProducts)

    const urlPosts = `/wp-json/wp/v2/posts?search=${phrase}`
    const configPosts = {
      method: "GET",
    }
    const posts = () => request(urlPosts, configPosts)

    const urlPages = `/wp-json/wp/v2/pages?search=${phrase}`
    const configPages = {
      method: "GET",
    }
    const pages = () => request(urlPages, configPages)

    return Promise.all([orders(), posts(), pages()])
  }
  sendMessage(email, level) {
    const url = "/wp-json/contact-form-7/v1/contact-forms/1717/feedback"
    const data = new FormData()
    data.append("email", email)
    data.append("level", level)

    const config = {
      method: "POST",
      multipart: true,
      data,
    }
    return request(url, config)
  }

  getDeliveryOptions() {
    // POST /wp-json/wc/v3/shipping/zones
    // POST /wp-json/wc/v3/shipping/zones/<id>/methods
    const url = "/wp-json/wc/v3/shipping/zones"
    const config = {
      method: "GET",
      withCredentials: true,
      auth: {
        username: KEY,
        password: SECRET,
      },
    }
    return request(url, config).then((data) => {
      const [selected] = data.filter((el) => el.name === "Remote")
      const url = `/wp-json/wc/v3/shipping/zones/${selected.id}/methods`
      const config = {
        method: "GET",
        withCredentials: true,
        auth: {
          username: KEY,
          password: SECRET,
        },
      }
      return request(url, config)
    })
  }

  ///////VALIDATION/////////
  registerAndGetToken(customer) {
    const url = `wp-json/wp/v2/users/register`
    let newUsername = customer.first_name + customer.last_name
    let newPassword = Math.random().toString(36).substring(7)
    let nameHash = newUsername + Math.random().toString(36).substring(7)
    let emailHash = customer.email + Math.random().toString(36).substr(2, 5);
    const config = {
      method: "POST",
      data: {
        username: nameHash,
        email: emailHash,
        password: newPassword,
      },
    }
    return request(url, config).then(() => {
      const url = `/wp-json/jwt-auth/v1/token`
      const config = {
        method: "POST",
        data: {
          username: nameHash,
          password: newPassword,
        },
      }
      return request(url, config)
    })
  }

  validateToken() {
    const token = localStorage.getItem("token")
    const url = `/wp-json/jwt-auth/v1/token/validate`
    const config = {
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
    }
    return request(url, config)
  }

  deleteToken() {
    const token = localStorage.getItem("token")
    const url = `/wp-json/jwt-auth/v1/token/cancellation`
    const config = {
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
    }
    return request(url, config)
  }
}

const WPApi = new WPAPI()

export default WPApi
